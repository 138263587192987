/*
  THEME RENAULT 1.0
*/
body, html { 
  color: #333;
  font-family: arial, sans-serif;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  font-smooth: always;
  font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
  font-weight: 300
  }

body {
  -ms-overflow-style: scrollbar;
}
  
  a             {color: #FC3;    text-decoration: underline;}
  a:hover         {color: #FC3;    text-decoration: underline;}
  a:focus, a:hover  {color: #FC3;    text-decoration: underline;}

  /* Gutter */
  .container {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }

  .row {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }

  [class*='col-'] {
    padding-left:7.5px;
    padding-right:7.5px;
  }

  [class*='col-'].bg-grey {    
    margin-bottom: 20px;
    margin-right: 7.5px;
    padding-right: 0px;
    margin-left: 7.5px;
    padding-left: 0px;
  }

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

.clearfix {
  display: block;
  clear: both;
}

.top-0 {
  margin-top: 0px !important;
}

.top-5 {
  margin-top: 5px !important;
}

.top-7 {
  margin-top: 7px !important;
}

.top-10 {
  margin-top: 10px !important;
}

.top-15 {
  margin-top: 15px !important;
}

.top-20 {
  margin-top: 20px !important;
}

.top-25 {
  margin-top: 25px !important;
}

.top-30 {
  margin-top: 30px !important;
}

.right-0 {
  margin-right: 0px !important;
}

.right-5 {
  margin-right: 5px !important;
}

.right-7 {
  margin-right: 7px !important;
}

.right-10 {
  margin-right: 10px !important;
}

.right-15 {
  margin-right: 15px !important;
}

.right-20 {
  margin-right: 20px !important;
}

.right-25 {
  margin-right: 25px !important;
}

.right-30 {
  margin-right: 30px !important;
}

.bottom-0 {
  margin-bottom: 0px !important;
}

.bottom-5 {
  margin-bottom: 5px !important;
}

.bottom-7 {
  margin-bottom: 7px !important;
}

.bottom-10 {
  margin-bottom: 10px !important;
}

.bottom-15 {
  margin-bottom: 15px !important;
}

.bottom-20 {
  margin-bottom: 20px !important;
}

.bottom-25 {
  margin-bottom: 25px !important;
}

.bottom-30 {
  margin-bottom: 30px !important;
}

.left-0 {
  margin-left: 0px !important;
}

.left-5 {
  margin-left: 5px !important;
}

.left-7 {
  margin-left: 7px !important;
}

.left-10 {
  margin-left: 10px !important;
}

.left-15 {
  margin-left: 15px !important;
}

.left-20 {
  margin-left: 20px !important;
}

.left-25 {
  margin-left: 25px !important;
}

.left-30 {
  margin-left: 30px !important;
}

.p-top-0 {
  padding-top: 0px !important;
}

.p-top-5 {
  padding-top: 5px !important;
}

.p-top-7 {
  padding-top: 7px !important;
}

.p-top-10 {
  padding-top: 10px !important;
}

.p-top-15 {
  padding-top: 15px !important;
}

.p-top-20 {
  padding-top: 20px !important;
}

.p-top-25 {
  padding-top: 25px !important;
}

.p-top-30 {
  padding-top: 30px !important;
}

.p-right-0 {
  padding-right: 0px !important;
}

.p-right-5 {
  padding-right: 5px !important;
}

.p-right-7 {
  padding-right: 7px !important;
}

.p-right-10 {
  padding-right: 10px !important;
}

.p-right-15 {
  padding-right: 15px !important;
}

.p-right-20 {
  padding-right: 20px !important;
}

.p-right-25 {
  padding-right: 25px !important;
}

.p-right-30 {
  padding-right: 30px !important;
}

.p-bottom-0 {
  padding-bottom: 0px !important;
}

.p-bottom-5 {
  padding-bottom: 5px !important;
}

.p-bottom-7 {
  padding-bottom: 7px !important;
}

.p-bottom-10 {
  padding-bottom: 10px !important;
}

.p-bottom-15 {
  padding-bottom: 15px !important;
}

.p-bottom-20 {
  padding-bottom: 20px !important;
}

.p-bottom-25 {
  padding-bottom: 25px !important;
}

.p-bottom-30 {
  padding-bottom: 30px !important;
}

.p-left-0 {
  padding-left: 0px !important;
}

.p-left-5 {
  padding-left: 5px !important;
}

.p-left-7 {
  padding-left: 7px !important;
}

.p-left-10 {
  padding-left: 10px !important;
}

.p-left-15 {
  padding-left: 15px !important;
}

.p-left-20 {
  padding-left: 20px !important;
}

.p-left-25 {
  padding-left: 25px !important;
}

.p-left-30 {
  padding-left: 30px !important;
}

.d-none { 
  display: none !important; 
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.font-italic {
  font-style: italic !important;
}

.font-weight-bold { 
  font-weight: bold !important; 
}

.text-grey {
  color: #777 !important;
}

.text-dark { 
  color: #333 !important; 
}

.text-muted { 
  color: #aaa !important; 
}

.text-decoration-none {
  text-decoration: none !important;
}

.bg-success {background-color: #dff0d8 !important;}

.bg-warning {background-color: #fcf8e3 !important;}

.bg-default {
  background-color: #d8d8d8 !important;
}

.form-control:focus {    border-color: #FC3;box-shadow: inset 0 1px 1px rgba(255, 204, 51, 0.3),0 0 8px rgba(255, 204, 51, 0.4);-webkit-box-shadow :inset 0 1px 1px rgba(255, 204, 51, 0.3),0 0 8px rgba(255, 204, 51, 0.4)}

h2.title {margin-top: 20px;margin-bottom: 30px;}

.row-eq-height > .bg-grey,
.row-eq-height > .col-eq-height,
.row-eq-height > .bg-grey > .panel,
.row-eq-height > .col-eq-height > .panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.row-eq-height > .bg-grey,
.row-eq-height > .col-eq-height,
.row-eq-height > .bg-grey > .panel,
.row-eq-height > .col-eq-height > .panel,
.row-eq-height > .bg-grey > .panel > .panel-body,
.row-eq-height > .col-eq-height > .panel > .panel-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 auto;
  flex: 1 auto;
}

.bg-grey {background: #EEEEED;}

.bg-white {
  background-color: #ffffff !important;
}

.bg-grey .panel {
  margin-bottom: 0;
}

.transparent {background-color: transparent !important;}

.mg-tb-15 {margin: 15px 0}
.mgt60    {margin-top: 60px;}

.v-resize {
  resize: vertical;
}

.v-align-middle {
  vertical-align: middle;
}

.select2 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

textarea.disabled {
  pointer-events: none;
}

.img-thumbnail {
  transition: none;
}

/*------------------------------------------------
  TOPLINKS 
------------------------------------------------*/
#toplinks-container {
  background: #FC3;
  height: 30px;
  line-height: 30px;}
#toplinks .links--profiles {float: left;
font-family: helv-57, arial, sans-serif;
font-size: .75em;
text-transform: uppercase;
}

.links--profiles li:last-child a { border: none; border-radius: 0; }
.links--profiles li:last-child a:hover, .links--profiles li:last-child a:focus { background-color: transparent; text-decoration: underline; }

#toplinks-container .links--profiles>.vous {   
   position: relative;
    display: inline-block;
    padding:0 15px;
    margin-right: 5px;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    color: #fff;
    background: #1a1b1d;
    font-family: helv-57,arial,sans-serif;
    font-size: .75em;
    text-transform: uppercase;
}
#toplinks-container .links--profiles>.vous:before {
  top: 7px;
    border-color: transparent transparent transparent #ffffff;
    border-width: 8px;
}
#toplinks-container .links--profiles>.vous:after, #toplinks-container .links--profiles>.vous:before {
  content: '';
    display: block;
    position: absolute;
    left: 100%;
    width: 0;
    height: 0;
    border-style: solid;
}
#toplinks-container .links--profiles>.vous:after {
  top: 8px;
    border-color: transparent transparent transparent #1a1b1d;
    border-width: 7px;
}

.nav-pills>li+li { margin-left: 10px;}

/*------------------------------------------------
  BREADCRUMB 
------------------------------------------------*/
.breadcrumb-container { background-color:#eae9e8; border-bottom: 1px solid #d2d0cd; margin-bottom: 30px; }
.breadcrumb {margin: 0; padding: 8px 0 5px; background-color:#eae9e8; font-size: .75em;}
.breadcrumb > li > a { color: #777; margin: 0 5px; }
.breadcrumb a:hover, .breadcrumb a:focus, .breadcrumb a.active { color: #d2d2d2; text-decoration:none;}
.breadcrumb > li + li::before {color: #000; content :"  "; background-image: url(/images/chevron-light.png); background-repeat:  no-repeat; background-position:  50% 0%;}
/*------------------------------------------------
  NAV 
------------------------------------------------*/
.navbar {border-radius: 0px;min-height: 30px; margin-bottom: 0;}
.navbar-nav {text-transform: uppercase;letter-spacing: .5px; }

.navbar-right {margin-right: 10px;}
.navbar .container, .navbar-collapse {padding-right: 0; padding-left:0; }

.navbar a {text-decoration:none;}

.navbar-default {
  background-color:#ffffff;
  border:none;
    -webkit-box-shadow: 0 5px 5px #dcdcdc;
    box-shadow: 0 5px 5px #dcdcdc;
    }
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
  background-color: transparent;
  color: #FC3;
}
.navbar-default .navbar-nav>.active>a:after {border-bottom: #2047e0}

.navbar-brand {
      padding: 10px 10px;
}

.nav>li a           {padding: 0px 15px;border-right: 1px solid #d2d2d2}
.nav-tabs>li>a, .nav-tabs.nav-justified>li>a    {padding: 5px 15px;border-radius: 0; border-right: none;color: #aaa;text-decoration: none;}
.nav-tabs         {margin-bottom: 15px;    border-bottom: 1px solid #EEEEED;background: #EEEEED; font-size: 18px;}
.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover { color: #333; border: 1px solid #EEEEED; border-bottom-color: transparent;}


#date {padding: 0px 10px;}

.logo-group {color: #FC3;font-family: helv-77, arial, sans-serif;text-transform: uppercase;float: right;
font-size: 2.1875em;
padding: 27px 0 28px;
text-align: left;}
.logo-group em {color: #000; font-style:normal;}
.logo-brands {margin: 36px 40px 0 0;float: right}
.logo {
  height: 100%;
  font-family: helv-47, arial, sans-serif;
  font-size: .875em;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  padding-top: 10px;
  padding-left: 20px
}



/*------------------------------------------------
  BTN 
------------------------------------------------*/
/* BTN PRIMARY*/
.btn-primary {
  color: #333;
  background-color: #FC3;
  border-color: #FC3;
}
.btn-primary:hover {
  color: #333;
  background-color: #FC3;
  border-color: #FC3;
}
.btn-primary.focus, .btn-primary:focus {
  color: #333;
  background-color: #FC3;
  border-color: #FC3;
}
.btn-primary.active, .btn-primary:active, .open>.dropdown-toggle.btn-primary {
  color: #333;
  background-color: #FC3;
  border-color: #FC3;
}
.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, 
.btn-primary:active:focus, .btn-primary:active:hover, .open>.dropdown-toggle.btn-primary.focus, 
.open>.dropdown-toggle.btn-primary:focus, .open>.dropdown-toggle.btn-primary:hover {
  color: #333;
  background-color: #FC3;
  border-color: #FC3;
}
/*********/

.btn-group a {
  border-right: 1px solid #fff;
}
.btn-group a:last-child {
  border-right: none;
}
.btn-group .btn+.btn, .btn-group .btn+.btn-group, .btn-group .btn-group+.btn, .btn-group .btn-group+.btn-group {
  margin-left:0; 
}

.btn-default, .btn-default:hover, .btn-default:focus, .btn-default:active {
  color: #333;
  border: 1px solid #d2d2d2 !important;
  background-color: #d2d2d2 !important;
}
.btn-default, .btn-primary, .btn-default:active, .btn-primary:active {
  background-image: url(/images/chevron.png);
  background-position: 10px center;
  background-repeat: no-repeat;
  padding: 6px 10px 5px 25px;

  
}
.btn-default:hover {
  border-color: inherit;
}
.btn.active, .btn:active {
      box-shadow:none
}
.btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, 
.btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, 
.open>.dropdown-toggle.btn-default.focus, .open>.dropdown-toggle.btn-default:focus, 
.open>.dropdown-toggle.btn-default:hover {
  border-color: inherit; 
}


.btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default {
  border-color:inherit ;
}

.btn > span.glyphicon {
  padding: 3px 0px;
}


/* Material  effect */
.btn {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  border-radius:0px;
  font-weight: 300;
  text-decoration: none;
}
/* .row-btn .btn {
  margin-bottom: 5px;
} */
.btn > span {
  position: relative;
  display: block;
  padding: 15px 25px;
}

.file-input-group {
  display: flex;
}

.file-input-group .file-name {
  height: auto;
}

.file-group {
  width: auto;
}

.image-preview-input span,
.image-preview-clear span,
.file-group span {
  display: inherit;padding:0;
}

.image-preview-input,
.file-btn {
  position: relative;
  overflow: hidden;
  margin: 0px;    
  color: #333;
  background-color: #fff;
  border-color: #ccc;    
}

.image-preview-input input[type=file],
.file-input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.image-preview-input-title,
.file-title {
    margin-left:2px;
}

.file-clear {
  height: 100%;
}

.btn.image-preview-clear,
.file-clear {
  padding: 5px 12px;
}

.red-ripple > .material-ink {
  background-color: red;
}
.material-ink {
  position: absolute;
  background: #bdc3c7;
  border-radius: 50%;
  transform: scale(0);
  opacity: 0.4;
}
.material-ink.animate {
  animation: ripple 0.6s linear;
}

@keyframes ripple {
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

#btn-imprimer-lot {
  margin-right: 20px;
}

/* Nouveaux boutons avec icônes */

.switch-order {
  display: flex;
  align-items: center;
  color: #333333;
}

.switch-order .btn.badge-order {
  background-color: #d8d8d8;
  width: 50px;
  height: 30px;
  text-align: center;
  margin-right: 10px;
  border: none;
  pointer-events: none;
}

.switch-order .btn.btn-order {
  display: block;
  width: 50px;
  height: 20px;
  margin: 0;
  padding: 0;
  border: none;
  background-color: #ffcc33;
  background-repeat: no-repeat;
  background-position: center;
}

.switch-order .btn.btn-order[data-direction="up"] {
  background-image: url('/images/chevron-up.png');
}

.switch-order .btn.btn-order[data-direction="down"] {
  background-image: url('/images/chevron-down.png');
}

.switch-order .btn.btn-order:first-child {
  margin-bottom: 5px;
}

.btn-icon {
  width: 50px;
  height: 30px;
  border: none;
}

.text-muted .btn-icon, .text-muted.btn-icon {
  filter: alpha(opacity=65);
  opacity: .65;
}

.btn-activer-default, .btn-activer-default:active {
  background-image: url('/images/btn-activer-default.jpg');
}

.btn-apercu-default, .btn-apercu-default:active {
  background-image: url('/images/btn-apercu-default.jpg');
}

.btn-apercu-primary, .btn-apercu-primary:active {
  background-image: url('/images/btn-apercu-primary.jpg');
}

.btn-creer-mvt-default, .btn-creer-mvt-default:active {
  background-image: url('/images/btn-creer-mvt-default.jpg');
}

.btn-desactiver-primary, .btn-desactiver-primary:active {
  background-image: url('/images/btn-desactiver-primary.jpg');
}

.btn-engager-mvt-default, .btn-engager-mvt-default:active {
  background-image: url('/images/btn-engager-mvt-default.jpg');
}

.btn-modifier-default, .btn-modifier-default:active {
  background-image: url('/images/btn-modifier-default.jpg');
}

.btn-modifier-primary, .btn-modifier-primary:active {
  background-image: url('/images/btn-modifier-primary.jpg');
}
.btn-transprez-default, .btn-transprez-default:active {
  background-image: url('/images/btn-transprez-default.png');
}

.btn-nombre-objets-default, .btn-nombre-objets-default:active {
  color: #333;
  text-align: left;
  padding-left: 6px;
  cursor: default;
  background: #d8d8d8 url('/images/btn-nombre-objets-default.jpg') no-repeat right;
  width: 70px;
}

.btn-pdf-default, .btn-pdf-default:active {
  background-image: url('/images/btn-pdf-default.jpg');
}

.btn-qrcode-primary, .btn-qrcode-primary:active {
  background-image: url('/images/btn-qrcode-primary.jpg');
}

.btn-selectionner-primary, .btn-selectionner-primary:active {
  background-image: url('/images/btn-selectionner-primary.jpg');
}

.btn-supprimer-default, .btn-supprimer-default:active {
  background-image: url('/images/btn-supprimer-default.jpg');
}

.btn-warning-default, .btn-warning-default:active {
  background-image: url('/images/btn-warning-default.png');
}

.btn-warning-primary, .btn-warning-primary:active {
  background-image: url('/images/btn-warning-primary.png');
}

.btn-warning-orange, .btn-warning-orange:active {
  background-image: url('/images/btn-warning-orange.png');
}

dd .btn.btn-warning-default,
dd .btn.btn-warning-primary,
dd .btn.btn-warning-orange {
  margin: 0;
}

.padding-arrow-group {
  padding-bottom: 30px;
}

.btn-arrow-group {
  position: absolute;
  width: 100%;
  height: 30px;
  bottom: 0;
  left: 0;
  padding: 0;
  text-align: center;
}

.btn-arrow-show, .btn-arrow-hide {
  height: 30px;
  width: 50px;
  padding: 0;
  position: absolute;
  margin-left: -25px;
  left: 50%;
  top: 0;
}

.btn-arrow-show, .btn-arrow-show:active {
  background: url('/images/chevron-down.png') center no-repeat;
}

.btn-arrow-hide, .btn-arrow-hide:active {
  background: url('/images/chevron-up.png') center no-repeat;
}

.material-icons {
  vertical-align: middle;
}

/* Fin nouveaux boutons avec icônes */

/*------------------------------------------------
  ROW HEIGHT 
------------------------------------------------*/
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
}

/*------------------------------------------------
  PANEL 
------------------------------------------------*/
.panel {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  /* transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
  -webkit-box-shadow:0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 0px;
  }
.panel:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.panel-heading .button-wrap {float: right}

.panel-default>.panel-heading .badge {
    color: #333;
    background-color: #ccc;
    float: left;
    margin: 7px 10px;
}
.panel-default {
  border:none;
}

.panel-default>.panel-heading {
  color: #333;
  background-color:#f7f7f7;
  border:none ;
}

.text-danger .panel-default > .panel-heading, 
.panel-default.text-danger > .panel-heading,
.panel-default > .panel-heading.text-danger {
  color: #a94442;
}

.panel-heading {
  padding: 5px 15px;
  overflow: hidden;
  border-bottom:0;
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.no-items {
  height: 250px;
}
  
.panel-title {
  font-size: 18px;
  line-height: 1.9;
  float: left
}

.panel-footer {
  background-color:transparent;
  border-top: none; 
  padding-top: 0px;
  padding-bottom: 15px;
}

.admin-panels-container .panel {
  position: relative;
}

.admin-panels-container img {
  mix-blend-mode: multiply;
}

.admin-panels-container .row [class*='col-'] .panel {
  background: rgb(255, 214, 9) none repeat scroll 0% 0%;
}

.admin-panels-container .row [class*='col-']:nth-child(2n) .panel {
  background: rgb(255, 232, 39) none repeat scroll 0% 0%;
}

.admin-panels-container .panel,
.admin-panels-container .panel-footer .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.admin-panels-container .panel {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.admin-panels-container p {
  margin: 5px 0 5px 0;
}

.admin-panels-container .panel-footer .text-right {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

#admin-panels-container .panel {
  min-height: 247px;
}

#ref-panels-container .panel {
  min-height: 170px;
}

.list-group {
  overflow-y: auto;
}

.panel .list-group-item {
  @include clearfix;
  clear: both;
  text-decoration: none;
}

.panel>.list-group .list-group-item { width: 100%;}

.panel .btn-panel {position: absolute; right: 10px;top: 15px;}

.panel>.list-group:last-child .list-group-item:last-child, .panel>.panel-collapse>.list-group:last-child .list-group-item:last-child {border-bottom-right-radius: 0;border-bottom-left-radius: 0;}

.panel-striped>.list-group>.list-group-item:nth-of-type(odd) {background-color: #FECB45;}

.panel-striped>.list-group>.list-group-item.odd {background-color: #FECB45;}
.panel-striped>.list-group>.list-group-item.even {background-color: #FCDA7E;}

.panel-striped>.list-group>.list-group-item.shown.odd {background-color: #EEEEED;}
.panel-striped>.list-group>.list-group-item.shown.even {background-color: #FFFFFF;}

.list-bg-1 {background-color: #FCDA7E;border: none; margin-bottom: 0;}
.list-bg-destroy {    background: repeating-linear-gradient( 45deg, rgba(252, 218, 126, 0.8), #eeeeed 3px, rgba(255, 204, 51, 0.4) 5px, #eeeeed 5px );border: none}

.list-group-item { text-decoration: initial;}
.list-group-item dt {margin-bottom: 5px;}
.list-group-item dl {margin-bottom: 10px;}

dd .btn { font-size: 12px;margin-top: 15px; }

.btn-panel-group {
  padding: 0;
  position: absolute;
  top: 10px;
  right: 10px;
}

.btn-panel-icon-group {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -15px;
  z-index: 999;
}

.btn-panel-icon-group .btn-icon:nth-child(n+2) {
  margin-left: 15px;
}

table .btn-icon, .btn-margin {
  margin: 5px;
}

.btn-margin-vertical {
  margin: 5px 0;
}

.btn-panel-group .btn-panel {
  position: relative;
  top: 0;
  right: 0;
  margin: 5px 0;
  width: 100%;
}

/*------------------------------------------------
  MODAL 
------------------------------------------------*/
.modal {
  padding: 0 !important;
}

.modal-content {border-radius: 0px;}
.modal-header {border-bottom: 1px solid #eee;}
.modal-footer {
  /*padding:5px;*/
  border-top: 1px solid #eee;
}


/*------------------------------------------------
  LOGIN 
------------------------------------------------*/

  .connect-form { width:480px; margin:0 auto;}
  .connect-form h2 {margin: 20px 0 20px;}
  .connect-form {margin-top: 100px}
  .connect-form .panel {background: #FFCC33;}
  .connect-form .logo-group {float:none;text-align: center;padding: 0px 0 10px;}
  .connect-form .logo-group img { display: block; margin: 0 auto 10px auto; }
  .connect-form .panel-body {padding: 5px 25px;}
  .bg-full {
      background-image: url(/images/bg-full-log.png) ;
      background-position: center center;
      background-repeat:  no-repeat;
      background-attachment: fixed;
      background-size:  cover;
      background-color: #333;
  }
  .connect-form footer {position: fixed;bottom: 10px; left: 0; right: 0; z-index: -1;}
  .connect-form footer a {color: #fff}

/*------------------------------------------------
  PAGINATION 
------------------------------------------------*/
.pagination { margin: 5px 0;}
.pagination>.active>a, .pagination>.active>a:focus, 
.pagination>.active>a:hover, .pagination>.active>span, 
.pagination>.active>span:focus, .pagination>.active>span:hover {
  background-color: #FC3;
    border-color: #FC3;
    color: #333;
}
.pagination>li>a, .pagination>li>span {
  color: #FC3;
}
.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover {
  color: #FC3;
}
.pagination>.disabled>a, .pagination>.disabled>a:focus, .pagination>.disabled>a:hover, 
.pagination>.disabled>span, .pagination>.disabled>span:focus, .pagination>.disabled>span:hover{
  color: #ddd;
}
/*------------------------------------------------
  CHECKBOX / RADIO 
------------------------------------------------*/
table .checkbox, table .md-checkbox,  table .radio {
  margin-top: inherit;
    margin-bottom: inherit;
}
.md-checkbox {
  display: block;
  /* margin-bottom: 20px;
  margin-top: 20px;
  min-height: 24px; */
  margin-bottom: 15px;
  margin-top: 0px;
  min-height: 20px;
  position: relative;
}
.md-checkbox label {
  cursor: pointer;
  font-weight: normal;
  padding-left: 30px;
}
.md-checkbox input[type="checkbox"] {
  opacity: 0;
  filter: alpha(opacity=0);
  margin-left: -30px;
  position: absolute;
}
.md-checkbox input[type="checkbox"] + .checkbox {
  border-radius: 2px;
  border-style: solid;
  border-width: 2px;
  content: " ";
  cursor: pointer;
  display: inline-block;
  height: 20px;
  left: 0;
  margin-left: 0;
  margin-top: 0;
  position: absolute;
  top: 0;
  visibility: visible;
  width: 20px;
  -webkit-animation: checkbox-bg-off 0.25s;
  -o-animation: checkbox-bg-off 0.25s;
  animation: checkbox-bg-off 0.25s;
}
.md-checkbox input[type="checkbox"] + .checkbox:after {
  border-color: transparent;
  border-style: solid;
  border-width: 0 0 2px 2px;
  content: " ";
  cursor: pointer;
  height: 8px;
  left: 50%;
  margin-left: -7px;
  margin-top: -6px;
  position: absolute;
  top: 50%;
  width: 14px;
  -webkit-animation: checkbox-marker-off 0.2s;
  -o-animation: checkbox-marker-off 0.2s;
  animation: checkbox-marker-off 0.2s;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.md-checkbox input[type="checkbox"]:disabled + .checkbox {
  cursor: not-allowed;
}
.md-checkbox input[type="checkbox"]:focus + .checkbox:before {
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
  width: 50px;
  opacity: 0.08;
  filter: alpha(opacity=8);
}
.md-checkbox input[type="checkbox"]:checked + .checkbox {
  -webkit-animation: checkbox-bg-on 0.1s;
  -o-animation: checkbox-bg-on 0.1s;
  animation: checkbox-bg-on 0.1s;
  border-width: 10px;
}
.md-checkbox input[type="checkbox"]:checked + .checkbox:after {
  -webkit-animation: checkbox-marker-on 0.15s;
  -o-animation: checkbox-marker-on 0.15s;
  animation: checkbox-marker-on 0.15s;
}
.md-checkbox input[type="checkbox"]:checked:focus + .checkbox:before {
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.md-checkbox + .checkbox {
  margin-top: -5px;
}
.md-checkbox input[type="checkbox"] + .checkbox {
  border-color: #FC3;
}
.md-checkbox input[type="checkbox"]:checked + .checkbox:hover {
  border-color: #FC3;
}
.md-checkbox input[type="checkbox"]:checked + .checkbox:after {
  border-color: white;
}
.md-checkbox input[type="checkbox"]:disabled + .checkbox, .md-checkbox input[type="checkbox"]:disabled + .checkbox:hover {
  border-color: #999999;
}
.md-checkbox input[type="checkbox"]:checked:focus + .checkbox {
  border-color: #FC3;
}
.md-checkbox input[type="checkbox"]:checked:focus + .checkbox:before {
  background-color: #FC3;
}

.radio input[type=radio], label.radio-inline input[type=radio] {    
  opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden;
}
.radio input[type=radio]:checked~.circle, label.radio-inline input[type=radio]:checked~.circle {border-color: #FC3;}
.radio .circle, label.radio-inline .circle {    
  border: 2px solid #FC3;
    height: 15px;
    width: 15px;
    border-radius: 100%;
}
.radio span, label.radio-inline span {    
  display: block;
    position: absolute;
    left:2px;
    top: 2px;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
    transition-duration: .2s;
}
.radio input[type=radio]:checked~.check, label.radio-inline input[type=radio]:checked~.check {    
  background-color: #FC3;    
  -webkit-transform: scale3d(.55,.55,1);
    transform: scale3d(.55,.55,1);
}
.radio input[type=radio]:checked~.check, .radio input[type=radio]:checked~.circle, label.radio-inline input[type=radio]:checked~.check, label.radio-inline input[type=radio]:checked~.circle{
      opacity: 1;
}
.radio .check, label.radio-inline .check {
  height: 15px;
    width: 15px;
    border-radius: 100%;
    -webkit-transform: scale3d(0,0,0);
    transform: scale3d(0,0,0);}

.form-group .radio label {    
  font-weight: 300;
    font-size: 14px;
    color: initial;
    margin-right: 10px;
    margin-left: 10px;
}


/*------------------------------------------------
  TABLE 
------------------------------------------------*/
thead, thead th {background: #FC3;}
.table>thead>tr>th {border-bottom: 2px solid #fff; font-weight: 400;}

.td-inline {display: inline; display: -webkit-inline-box;}
.td-inline a {padding: 5px 10px; border-right: 1px solid #ccc;vertical-align: middle; color: #333}
.td-inline a:hover {color:  #FC3}
.td-inline a:last-child {border-right: none;}

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
  vertical-align: middle;
  border-top: none; 
  line-height: inherit
}

/*Panel table with filters*/
.filterable .filters input[disabled] {
    background-color: transparent;
    border: none;
    cursor: auto;
    box-shadow: none;
    padding: 0;
    height: auto;
}
.filterable .filters input[disabled]::-webkit-input-placeholder {
    color: #333;
}
.filterable .filters input[disabled]::-moz-placeholder {
    color: #333;
}
.filterable .filters input[disabled]:-ms-input-placeholder {
    color: #333;
}

.warning-text {
  color: #FF7F27;
}

.table > tbody > tr.disabled-row.odd {
  background-color: #d8d8d8;
}

.table > tbody > tr.disabled-row.even {
  background-color: #e5e5e5;
}

.table>tbody>tr.warning-row, 
.warning-bg, 
.warning-bg-form.form-group [data-toggle="floatLabel"] + label,
.warning-bg-form.form-group [data-toggle="floatLabel"] + .select2 + label,
.warning-bg-form.form-group [data-toggle="floatLabel"] + .bootstrap-datetimepicker-widget + label,
.warning-bg-form select,
.warning-bg-form input,
.warning-bg-form .select2-container--default .select2-selection--single,
.warning-bg-form .select2-container--default .select2-selection--single .select2-selection__rendered,
.warning-bg-form .form-control[disabled], 
.warning-bg-form .form-control[readonly], 
.warning-bg-form fieldset[disabled] .form-control {
  background-color: #FF7F27;
  color: #FFFFFF;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  cursor: not-allowed;
}

.warning-bg-form .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #FFFFFF transparent transparent transparent;
}

.warning-bg-form .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #FFFFFF transparent;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__arrow b {
  display: none;
}

.select2-container--disabled span.select2-selection.select2-selection--single {
  outline: none;
}

.table>tbody>tr.warning-row td:last-child {
  padding-right: 58px;
  position: relative;
}
.table>tbody>tr.warning-row td:last-child a {
  position: absolute;
  top: 0px;
  right: 0px;
}

.table-striped>tbody>tr.warning-row td:last-child {
  padding-right: 8px;
  position: static;
}
.table-striped>tbody>tr.warning-row td:last-child a {
  position: relative;
}
.table-striped>tbody>tr.warning-row {
  color: #FFFFFF;
}
.table-striped>tbody>tr.warning-row.even {
  background-color: #FF8B3C;
}
.table-striped>tbody>tr.warning-row.odd {
  background-color: #FF8531;
}
.table-hover>tbody>tr.warning-row:hover {
  background-color: #FF7F27;
}

/*------------------------------------------------
  FORM CONTROL 
------------------------------------------------*/
.form-control {
      border-radius: 2px;
      border: 1px solid #eee;
      box-shadow:inherit;
}

.bootstrap-datetimepicker-widget table td,
.bootstrap-datetimepicker-widget table th 
 {
     border-radius: 0;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
    background-color: #FC3;
    color:#333;
  
}

.js-date-realisation, .js-date-realisation-update {
  width: auto;
}

/*------------------------------------------------
  DATATABLES
------------------------------------------------*/
.dataTables_wrapper .dataTables_paginate .paginate_button {border: 0px solid transparent;padding:0;}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {    
  border: 0px solid transparent;
  background-color:transparent;
  background: none;
}

div.dataTables_wrapper div.dataTables_filter input {margin:0;}

/* FOR THE GRAB EFFECT HOVER DATATABLES */
.js-table-jalons .ui-sortable-handle:hover {
  cursor: move;
  cursor: url('/images/grab.cur'), move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}
.js-table-jalons .ui-sortable-helper {
  cursor: move !important;
  cursor: url('/images/grabbing.cur'), move !important;
  cursor: -webkit-grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: grabbing !important;
}

table.dataTable.no-footer {border-bottom:none;}
.dataTables_length {padding-left: 10px;}
div.dataTables_wrapper div.dataTables_info {padding-left: 10px;}

.dataTables_wrapper input[type='search'] { 
  padding-left: 30px;
  background-image: url('/images/loupe.png'); 
  background-size: contain;
  background-repeat: no-repeat;
}

.pagination>li {
     display: inline-table;
}

.search-icon  {
     display: inline-block;
     width: 34px;
     background: #FC3;
     padding: 3px;
}

.toolbar {
  float: left;
}

.dataTable.dt-actions tr td:last-child {
  display: flex;
}

.dt-form-group {
  width: 196px;
}

/*------------------------------------------------
  Floating Labels
------------------------------------------------*/
/* Basic Style */
.form-group {
    position: relative;   
}

.form-group [data-toggle="floatLabel"] {
    height: 44px;
    padding-top: 16px;
}

.form-group textarea[data-toggle="floatLabel"] {
  height: auto;
  min-height: 54px;
}

.form-group [data-toggle="floatLabel"]:not(select) + label,
.form-group [data-toggle="floatLabel"]:not(select) + .bootstrap-datetimepicker-widget + label {
  font-size: 10px;
  left: 4px;
  opacity: 1;
  position: absolute;
  top: 3px;
  /* transition: all 0.3s ease-in-out; */
  font-weight: 300;
  color: #b4b4b4;
}

.form-group [data-toggle="floatLabel"] + .select2 + label {
  font-size: 10px;
  left: 4px;
  opacity: 1;
  position: absolute;
  top: 3px;
  font-weight: 300;
  color: #b4b4b4;
}

.form-group select[data-toggle="floatLabel"] + label {
  font-size: 10px;
  left: 8px;
  opacity: 1;
  position: absolute;
  top: 3px;
  font-weight: 300;
  color: #b4b4b4;
}

.form-group select[data-toggle="floatLabel"][multiple][data-value=""]:not(.select2-hidden-accessible) + label {
  top: 12px;
  font-size: 16px;
}

/* Positioning */
.form-group [data-toggle="floatLabel"][data-value=""]:not(select) {
  padding-top: 6px;
}

.form-group textarea[data-toggle="floatLabel"][data-value=""] {
  padding-top: 11px;
}

.form-group [data-toggle="floatLabel"]:not(select):-webkit-autofill {
    padding-top: 16px;
}

.form-group [data-toggle="floatLabel"][data-value=""]:not(select) + label,
.form-group [data-toggle="floatLabel"][data-value=""]:not(select) + .bootstrap-datetimepicker-widget + label {
  top: 12px;
  font-size: 16px;
}

.form-group [data-toggle="floatLabel"]:not(select):-webkit-autofill + label,
.form-group [data-toggle="floatLabel"]:not(select):-webkit-autofill + .bootstrap-datetimepicker-widget + label {
  font-size: 10px;
  top: 3px;
}

.datetimepicker-fix {
  position: relative;
}

.form-group [data-toggle="floatLabel"] + .select2 .select2-selection {
  min-height: 44px;
  padding-top: 11px;
  padding-left: 4px;
  overflow: hidden;
}

.form-group [data-toggle="floatLabel"] + .select2 .select2-selection__arrow {
  height: 42px;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, 
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, 
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple {
     border: solid #FC3 1px;
    box-shadow: inset 0 1px 1px rgba(255, 204, 51, 0.3),0 0 8px rgba(255, 204, 51, 0.4);
    -webkit-box-shadow: inset 0 1px 1px rgba(255, 204, 51, 0.3),0 0 8px rgba(255, 204, 51, 0.4);
    outline: none;
}

.select2-selection {
  outline: none;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
      background-color: #FC3;
}

.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
    background-color: #fff;
    border: 1px solid #eee;
    /* border-radius: 0; */
}

.select2-container .select2-selection--single,
.select2-container .select2-selection--multiple {
  min-height: 34px;
  border-radius: 2px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  line-height: 32px;
  padding-left: 8px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow,
.select2-container--default .select2-selection--multiple .select2-selection__arrow {
  min-height: 32px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  min-height: 34px;
  border-radius: 2px;
  padding: 6px 12px;
}

.select2-dropdown {
  border-radius: 2px;
  overflow: hidden;
  z-index: 1060;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  height: 28px;
  line-height: 21px;
  max-width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li input {
  max-width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li span {
  line-height: 26px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #333;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #fc3;
}

.form-group label {
  margin-left: 9px;
}

.form-group .md-checkbox label {
  margin: 0;
}

.has-error {
  color: #d9534f;
}

.form-group label.has-error {
  margin-top: 5px;
  margin-bottom: 0;
  margin-left: 13px;
}

/*------------------------------------------------
  FOOTER
------------------------------------------------*/
#footer { border-top: 1px solid #9B9B9B; margin-top: 50px}
#footer p {padding: 15px 0}
#footer a {color:#9B9B9B }

/*------------------------------------------------
  Fix pour Datatables trop larges
------------------------------------------------*/
table.dataTable.nowrap td {
     white-space: normal; 
}

.container-modal .modal-dialog {
  width: calc(100% - 60px);
  max-width: 1187px;
  margin-left: auto;
  margin-right: auto;
}

#modal-rechercher-objet .modal-dialog {
  width: auto !important;
  margin: 30px;
}

#modal-rechercher-objet div.form-group { 
  /* margin-bottom: 10px; */
}

#modal-rechercher-objet div.panel-footer {
  padding: 0px;
}

/*------------------------------------------------
  Fix pour tooltips
------------------------------------------------*/

.tooltip {
  font-size: 14px;
}

.tooltip.fade {
  -webkit-transition: opacity 0s linear;
  -o-transition: opacity 0s linear;
  transition: opacity 0s linear;
}

.tooltip-visible.fade {
  -webkit-transition: opacity .15s linear;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear;
}

.tooltip.in {
  filter: alpha(opacity=0);
  opacity: 0;
}

.tooltip-visible.in {
  filter: alpha(opacity=90);
  opacity: .90;
}

.tooltip-inner {
  white-space: pre-wrap;
  max-width: 250px;
}

.tooltip-lg .tooltip-inner {
  max-width: calc(100vw - 30px);
}

/*------------------------------------------------
  Fix badges nav-tabs
------------------------------------------------*/
.nav-tabs .badge {
  background-color: transparent;
  margin-left: 5px;
  border: 1px solid #777;
  color: #777;
}

#menu-mouvements, #menu-presentations {
  margin-bottom: 0;
}

/*------------------------------------------------
  Fix tabs recherche
------------------------------------------------*/
.tab-pane:after {
   content: '';
   display: block;
   overflow: hidden;
   visibility: hidden;
   width: 0;
   height: 0;
   clear: both;
}

/*------------------------------------------------
  Loader datatables sans serverSide
------------------------------------------------*/
.no-more-tables.with-loader {
  position: relative;
}

.no-more-tables.with-loader .table-loader {
  position: absolute;
  top: 0;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  text-align: center;
  padding: 1em 0;
}

#no-more-tables td:before, 
.no-more-tables td:before {
  left: 0px;
  position: static;
  padding-right: 10px;
  font-weight: 300;
}

/*------------------------------------------------
  Titre avec boutons
------------------------------------------------*/
.title-with-actions .btn {
  margin: 20px 0 20px 20px;
}

.title-with-actions .btn:first-child {
  margin-left: 0;
}

/*------------------------------------------------
  Listes pour modales
------------------------------------------------*/
.list-error ul, 
ul.list-error  {
  list-style: none;
  padding: 0;
}

.list-error ul li, 
ul.list-error li {
  background-image: url('/images/chevron.png');
  background-position: 10px 7px;
  background-repeat: no-repeat;
  padding: 3px 10px 2px 25px;
}

.list-error span, 
span.list-error {
  display: inline-block;
  text-align: left;
}

.list-error strong {
  display: block;
}

.list-error strong + .list-error-subtitle {
  margin-top: 5px;
}

.swal2-content .form-group {
  font-size: 14px;
  text-align: left;
}

.swal2-content .has-error {
  line-height: 1.42857143;
}

.swal2-popup .list-error, 
.swal2-popup.list-error {
  width: auto;
  min-width: 32em;
}

.swal2-popup .list-error .swal2-content, 
.swal2-popup.list-error .swal2-content {
  font-size: 14px;
  line-height: 1.42857143;
}

.swal2-popup .list-error .swal2-content #swal2-content, 
.swal2-popup.list-error .swal2-content #swal2-content {
  color: #333;
}

.swal2-popup .list-error .swal2-content #swal2-content .list-error-header, 
.swal2-popup.list-error .swal2-content #swal2-content .list-error-header {
  color: #545454;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 21px;
  line-height: 21px;
  display: block;
  text-align: center;
}

.swal2-popup .list-error .swal2-content #swal2-content span ul:last-child, 
.swal2-popup.list-error .swal2-content #swal2-content span ul:last-child {
  margin-bottom: 0;
}

.swal2-btn-sm .swal2-actions button {
  font-size: 0.9125em !important;
  margin: 0 0.2625em;
  padding: 0.525em 1.65em;
}

/*------------------------------------------------
  Fix sweet alert 2
------------------------------------------------*/
.swal2-popup {
  font-size: 16px;
}

.swal2-icon.swal2-error .swal2-x-mark {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -o-flex;
  display: flex;
}

.swal2-popup .swal2-styled:focus{
  -webkit-box-shadow: none;
  box-shadow: none;
}

.panel-yellow { 
  background-color: #FFCC33 !important; 
}

#my-objects-table tbody tr td { 
  vertical-align:top; 
}

#my-objects-table.table>tbody>tr.warning-row td:last-child a { 
  top: 8px;
}

.btn-danger, .btn-danger:active {
  background-image: url('/images/chevron-blanc.png');
  background-position: 10px center;
  background-repeat: no-repeat;
  padding: 6px 10px 5px 25px;
}

/*------------------------------------------------
  Formulaire inline
------------------------------------------------*/
.form-horizontal .form-group {
    margin-right: 0px;
    margin-left: 0px;
}

/*------------------------------------------------
  Scrollbar
------------------------------------------------*/
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); 
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  transition: .2s all ease;
  background: rgba(255, 204, 51, 0.699); 
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); 
}

::-webkit-scrollbar-thumb:hover, ::-webkit-scrollbar-thumb:focus {
  background: #FFCC33; 
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #FFCC33; 
}

/*------------------------------------------------
  Divers
------------------------------------------------*/

.ellipsis-info {
  display: flex;
}

.ellipsis-info span:first-child {
  white-space: pre;
}

.ellipsis-info span:last-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.alert-content {
  display: inline-flex;
  flex-direction: column;
}

.flex-panel .panel-body {
  display: flex;
  flex-direction: column;
}

.flex-panel .btn-group:last-child {
  margin-top: auto;
}

/*------------------------------------------------
  @MEDIA
------------------------------------------------*/

  /*------------------------------------------------
    Fix pour tooltips
  ------------------------------------------------*/

  @media (max-width:280px) {
    .tooltip-lg .tooltip-inner {
      max-width: 250px;
    }
  }

  /*------------------------------------------------
    Fin fix pour tooltips
  ------------------------------------------------*/

  @media (max-width:320px) {
    .btn-group {
    vertical-align: inherit;
    }
    .btn-group a {
    border-right: 0px solid #fff;
      width: 100%;
      border-radius: 0;
      margin-bottom: 3px
    }
  }

  @media (max-width:414px) {
    .links--profiles li:last-child {float: right;}
  }

  @media (max-width:767px)  {

    .swal2-popup .list-error, .swal2-popup.list-error {
      width: 32em;
      min-width: auto;
    }

    .btn-group {vertical-align: inherit;}
    .row-eq-height {display: inherit; }

    .panel .btn-panel {    
      position: static;
      right:initial;
      top: 15px;
      margin-bottom: 15px;
      margin-top: 10px;
      width: 100%;
    }

    .btn-panel-group {
      position: relative;
      top: 0;
      right: 0;
      margin-bottom: 5px;
    }

    .btn-panel-icon-group {
      position: relative;
      top: 0;
      right: 0;
      margin: 10px 0 5px 8px;
    }
    
    .btn-panel-group .btn-panel {
      margin: 10px 0;
    }

    .sr-only {clip: rect(0,0,0,0);}
    .navbar-nav {margin: 10px 0px;}
    .container>.navbar-collapse,.container>.navbar-header { margin-right:0;margin-left:0;}
    [class*='col-'].bg-grey {
      max-width: -webkit-calc(100% - 15px);
      max-width: -moz-calc(100% - 15px);
      max-width: calc(100% - 15px);
    }
    .logo       {text-align: center;}
    .logo-brands  {display: none;}
    .logo-group   {text-align: left;float: initial;display: none}

    .navbar-toggle {position: absolute;right:0;top:-55px;}

    .nav>li a {padding: 10px 15px;    border-right:none;text-align: center;}
    .navbar-collapse .navbar-right {text-align: center; border-top: 1px solid #ddd; padding-top: 10px}

    .links--profiles li:last-child a {padding: 0; font-size: 11px;}
    .nav-pills>li+li { font-size: 12px;}
    .dl-objet { margin: 0px 20px 20px 20px; }

    /*------------------------------------------------
      Fix fiche présentation
    ------------------------------------------------*/

    .tpl_presentations_edit .navbar-nav, .tpl_presentations_edit .row-btn {
       padding-left: 15px;
    }

    .tpl_presentations_edit .container.banner {
      padding-right: calc(1200px - 100vw);
    }

    .tpl_presentations_edit .navbar-nav {
      padding-right: calc(1200px - 100vw);
    }

    .tpl_presentations_edit .navbar-toggle {
      right: calc(1200px - 100vw);
      margin-right: 0px;
    }

    /*------------------------------------------------
      Fin fix fiche présentation
    ------------------------------------------------*/

    .dl-horizontal dt {
      margin-left: 9px;
      margin-bottom: 5px;
    }

    dd {
      word-break: break-word;
    }

    .dl-horizontal dd {
      min-height: 20px;
      margin-bottom: 10px;
    }

    .dl-horizontal dd .form-group {
      margin-bottom: 5px;
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media (min-width:768px) {
    .padding-icon-group {
      padding-right: 80px;
    }

    .padding-icon-group-2 {
      padding-right: 145px;
    }

    .padding-icon-group-3 {
      padding-right: 210px;
    }
  }

  /*------------------------------------------------
    Fix cols
  ------------------------------------------------*/

  @media (min-width:768px) and (max-width:991px) {
    .col-xs-12 {
      width: auto;
    }
  }

  /*------------------------------------------------
    Fin fix cols
  ------------------------------------------------*/

  @media (min-width:769px) and (max-width:991px) {
    .btn-panel-group {
      top: 5px;
    }
    
    .btn-panel-group .btn-panel {
      margin: 10px 0 0 0;
      display: block;
    }
  }

  @media (max-width:991px) {

    .row-btn .btn {
      margin-top: 2.5px;
      margin-bottom: 2.5px;
    }

    .placeholder-col {
      display: none;
    }

    .row-eq-height {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      -o-flex-direction: column;
      flex-direction: column;
    }

    /*------------------------------------------------
      Fix fiche présentation
    ------------------------------------------------*/

    .tpl_presentations_edit #content {
      overflow: hidden;
    }

    .tpl_presentations_edit #content > .container:first-child > .row-eq-height > .col-eq-height:first-child {
      left: 25%;
    }

    .tpl_presentations_edit #content.presentation-valide > .container:first-child > .row-eq-height > .col-eq-height:first-child {
      left: 0;
      min-width: 1170px;
    }

    .tpl_presentations_edit #content > .container:first-child > .row-eq-height > .col-eq-height:nth-child(2) {
      left: -75%;
    }

    /*------------------------------------------------
      Fin fix fiche présentation
    ------------------------------------------------*/

    #order-one {
      order: 1;
    }

    .panel-auto {
      height: auto !important;
    }

    /*------------------------------------------------
      Titre avec boutons
    ------------------------------------------------*/
    .title-with-actions .title {
      margin-bottom: 20px;
    }

    .title-with-actions .btn {
      margin-top: 0;
    }
  }

  /*------------------------------------------------
    No more tables (responsive table)
  ------------------------------------------------*/

  @media (max-width: 992px) {
      
    /* Force table to not be like tables anymore */
    #no-more-tables table, 
    #no-more-tables thead, 
    #no-more-tables tbody, 
    #no-more-tables th, 
    #no-more-tables td, 
    #no-more-tables tr,
    .no-more-tables table, 
    .no-more-tables thead, 
    .no-more-tables tbody, 
    .no-more-tables th, 
    .no-more-tables td, 
    .no-more-tables tr { 
      display: block; 
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    #no-more-tables thead tr, .no-more-tables thead tr { 
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    #no-more-tables tr, .no-more-tables tr { border: 1px solid #ccc; }
   
    #no-more-tables td, .no-more-tables td { 

      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee; 
      position: relative;
      white-space: normal!important;
      text-align:left;
      font-weight: bold;
      font-size: 12px
    }
   
    /* Label the data */
    #no-more-tables td:before, .no-more-tables td:before { content: attr(data-title); }
  }

  /*------------------------------------------------
    Fin no more tables (responsive table)
  ------------------------------------------------*/

  /*------------------------------------------------
    Fix fiche présentation
  ------------------------------------------------*/

    @media (min-width:768px) and (max-width:1199px) {
      .tpl_presentations_edit .container.banner {
        padding-right: calc(1215px - 100vw);
      }
    }

    @media (max-width:1199px) {
      .tpl_presentations_edit {
        min-width: 1170px;
      }

      .tpl_presentations_edit .container {
        width: 1170px;
      }

      .tpl_presentations_edit #content > .container:first-child > .row-eq-height {
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        -o-flex-direction: row;
        flex-direction: row;
      }

      .tpl_presentations_edit .navbar-right, .tpl_presentations_edit #footer > .container, .tpl_presentations_edit .row-btn {
        padding-right: calc(1200px - 100vw);
      }
    }

  /*------------------------------------------------
    Fin fix fiche présentation
  ------------------------------------------------*/

  /*------------------------------------------------
    Fix pour Datatables trop larges
  ------------------------------------------------*/

  @media (min-width:993px) and (max-width:1199px) {
    #modal-rechercher-objet .modal-dialog {
      margin: 30px 10px;
    }

    .container-modal .modal-dialog {
      width: calc(100% - 20px);
    }
  }

  /*------------------------------------------------
    Fin fix pour Datatables trop larges
  ------------------------------------------------*/

/*------------------------------------------------
  Fin @MEDIA
------------------------------------------------*/
